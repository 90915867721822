<template>
    <div class="review-summary-rating">
        <div class="review-summary-rating-wrap" v-for="item of 5" :key="item">
            <div class="review-bars">
                <div class="full_bar">
                    <div class="bar_filler" style="width:0%"></div>
                </div>
            </div> {{ item }}
            <div class="review-stars">
                <div class="star-rating">
                    <span class="star" ></span> 
                    <!-- <fragment v-if="star < 5">
                        <span class="star" v-for="bstar of (star < 5)" :key="bstar"></span>
                    </fragment> -->
                </div>
            </div>
            <div class="review-avgs">
                0
            </div>
        </div>
        
    </div>
</template>

<script>
export default {
    props: {
        reviewSummary: Array
    },
    data() {
        return {
            reviewData: []
        }
    },
    created: function() {
        this.reviewData = this.$props.reviewSummary
        console.log(this.$props.reviewSummary)
    }
}
</script>